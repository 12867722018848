// file: apps/dashboard/src/pages/profile/styles.js
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    padding: 17,
    flexDirection: 'column',
    overflow: 'auto',
  },
  grid: {
    maxWidth: 689,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 48,
  },
  header: {
    marginBottom: 18,
    marginLeft: 25,
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 34,
  },
  itemList: {
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    paddingBottom: 26,
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.custom.primary1,
    fontWeight: 700,
  },
  editIcon: {
    color: theme.palette.custom.secondary2,
    position: 'absolute',
    right: 0,
    bottom: 15,
  },
  modalContainer: {
    backgroundColor: '#FFFFFF',
    padding: 24,
    maxWidth: 700,
    margin: '50px auto',
    borderRadius: 16,
    maxHeight: '90vh',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: 'none',
      height: '100%',
    },
  },
  modal: {
    overflow: 'scroll',
  },
  alert: {
    marginTop: 16,
    width: '100%',
  },
  // for added item
  item: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    padding: '16px 24px',
    marginBottom: 8,
    [theme.breakpoints.up('md')]: {
      padding: '16px 85px',
    },
  },
  itemTitle: {
    color: theme.palette.custom.primary1,
    fontWeight: 800,
    fontFamily: theme.typography.fontFamilyNunito,
  },
  itemTitle2: {
    color: theme.palette.custom.primary2,
    fontWeight: 600,
    fontFamily: theme.typography.fontFamilyNunito,
  },
  tableText: {
    color: theme.palette.custom.primary2,
  },
  itemValue: {
    color: theme.palette.custom.primary1,
    fontWeight: 400,
    fontFamily: theme.typography.fontFamilyNunito,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  valueContainer: {
    position: 'relative',
  },
}));

export default useStyles;
