import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  item: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    padding: '16px 24px',
    marginBottom: 8,
    [theme.breakpoints.up('md')]: {
      padding: '16px 40px',
    },
  },
  itemTitle: {
    color: theme.palette.custom.primary1,
    fontWeight: 700,
    fontFamily: theme.typography.fontFamilyNunito,
  },
  itemValue: {
    color: theme.palette.custom.primary1,
    fontWeight: 400,
    fontFamily: theme.typography.fontFamilyNunito,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  valueContainer: {
    position: 'relative',
  },
  editIcon: {
    color: theme.palette.custom.secondary2,
    position: 'absolute',
    right: 0,
    bottom: -7,
  },
}));

export default useStyles;
