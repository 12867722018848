import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import useStyles from './styles';

const ValueRow = ({ value, marginItemTop }) => {
  const { classes } = useStyles();
  return <Typography style={{ marginTop: marginItemTop ? 10 : 0 }} variant="body1" className={classes.itemValue}>{value}</Typography>;
};

ValueRow.defaultProps = {
  value: '',
  marginItemTop: false,
};

ValueRow.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  marginItemTop: PropTypes.bool,
};

const renderValue = ({ value, marginItemTop }) => {
  if (Array.isArray(value)) {
    // eslint-disable-next-line react/no-array-index-key
    return value.map((row, i) => <ValueRow marginItemTop={marginItemTop} value={row} key={i} />);
  }

  return <ValueRow marginItemTop={marginItemTop} value={value} />;
};

const ProfileItem = ({
  title, value, onEditItem, isWide, marginItemTop,
}) => {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.item}>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1" className={classes.itemTitle}>{title}</Typography>
      </Grid>
      <Grid
        style={{
          marginTop: isWide ? 10 : 0,
        }}
        item
        xs={12}
        sm={isWide ? 12 : 8}
        className={classes.valueContainer}
      >
        {value && renderValue({ value, marginItemTop })}
        {onEditItem && (
          <IconButton
            className={classes.editIcon}
            onClick={onEditItem}
          >
            <EditIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

ProfileItem.defaultProps = {
  value: '',
  onEditItem: null,
  isWide: false,
  marginItemTop: false,
};

ProfileItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.node,
  ]),
  onEditItem: PropTypes.func,
  isWide: PropTypes.bool,
  marginItemTop: PropTypes.bool,
};

export default ProfileItem;
