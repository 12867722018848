import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';

import { Button, FormLearnerInfoFields } from '~common/components';
import useStyles from './styles';
import PersonalDetailsFields from './components/personalDetailsFields';
import AboutYouFields from './components/aboutYouFields';
import ParentLearnerFields from './components/parentLearnerFields';
import AccountDetailsFields from './components/accountDetailsFields';

const EditFields = ({
  control, getValues, type, watch, trigger, setValue, setError, clearErrors, formState,
}) => {
  switch (type) {
    case 'account':
      return <AccountDetailsFields control={control} />;
    case 'personalDetails':
      return <PersonalDetailsFields control={control} setValue={setValue} getValues={getValues} />;
    case 'parentLearnerCreate':
    case 'parentLearnerEdit':
      return (
        <ParentLearnerFields
          control={control}
          watch={watch}
          trigger={trigger}
          setValue={setValue}
          getValues={getValues}
          type={type}
          formState={formState}
          setError={setError}
          clearErrors={clearErrors}
        />
      );
    case 'personalDetailsLearner':
      return (
        <PersonalDetailsFields
          control={control}
          getValues={getValues}
          setValue={setValue}
          showPhoneNumber
          showAddressFields={false}
        />
      );
    case 'personalDetailsBasic':
      return (
        <PersonalDetailsFields
          control={control}
          setValue={setValue}
          getValues={getValues}
          showAddressFields={false}
        />
      );
    case 'aboutYou':
      return (
        <AboutYouFields
          control={control}
          getValues={getValues}
          watch={watch}
          trigger={trigger}
          setValue={setValue}
          showFileUploadFields={false}
        />
      );
    case 'schoolInfo':
      return (
        <FormLearnerInfoFields
          type={type}
          trigger={trigger}
          control={control}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
        />
      );

    default:
      return null;
  }
};

EditFields.propTypes = {
  type: PropTypes.oneOf(['personalDetails',
    'personalDetailsBasic', 'aboutYou', 'schoolInfo',
    'parentLearnerCreate', 'parentLearnerEdit', 'personalDetailsLearner', 'account']).isRequired,
  getValues: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formState: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
};

const EditForm = ({
  title, formData, onSubmit, handleOnClose, type, validationSchema,
}) => {
  const { classes } = useStyles();
  const {
    handleSubmit, control,
    clearErrors, setError,
    formState,
    getValues, watch,
    setValue, trigger, formState: { dirtyFields },
  } = useForm(
    { defaultValues: formData, resolver: yupResolver(validationSchema) },
  );

  return (
    <form
      onSubmit={
        handleSubmit((data) => onSubmit({ data, type, dirtyFields }))
      }
    >
      <div className={classes.header}>
        <Typography variant="body1" className={classes.title}>{title}</Typography>
        <IconButton
          className={classes.closeButton}
          onClick={handleOnClose}
          size="large"
        >
          <Close color="action" />
        </IconButton>
      </div>
      <EditFields
        control={control}
        getValues={getValues}
        trigger={trigger}
        type={type}
        watch={watch}
        formState={formState}
        setValue={setValue}
        setError={setError}
        clearErrors={clearErrors}
      />
      <div className={classes.actionButtons}>
        <Button type="button" variant="text" onClick={handleOnClose}>Cancel</Button>
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
};

EditForm.defaultProps = {
  validationSchema: null,
};

EditForm.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['personalDetails', 'personalDetailsBasic', 'aboutYou', 'schoolInfo',
    'parentLearnerCreate', 'parentLearnerEdit', 'personalDetailsLearner', 'account']).isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  validationSchema: PropTypes.shape({}),
  formData: PropTypes.shape({}).isRequired,
};

export default EditForm;
