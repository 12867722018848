// file: apps/dashboard/src/pages/profile/index.jsx
import dayjs from 'dayjs';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  Alert,
  Grid,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import { config, validationSchemas, dataLists } from '~common/utils';
import { Button, TutorAgreement, ParentAgreement } from '~common/components';
import { getAgreement } from '~common/api/user';
import useStyles from './styles';
import PageTitle from '../../components/pageTitle';
import ProfileUser from '../../components/profileUser';
import ProfileItem from './components/profileItem';
import EditForm from '../../components/editForm';
import useEditUserForm from './hooks/useEditUserForm';

const { degreeLevelOptions, subjects } = dataLists;

const qualificationOrder = subjects.map((q) => q.qualificationLevel);

const sortSubjectList = (list) => {
  const sorted = [...list];
  sorted.sort((a, b) => {
    const subjectA = a.subject?.subjectName?.toLowerCase() || '';
    const subjectB = b.subject?.subjectName?.toLowerCase() || '';

    if (subjectA < subjectB) return -1;
    if (subjectA > subjectB) return 1;

    const qualA = a.qualificationLevel?.qualificationLevel || '';
    const qualB = b.qualificationLevel?.qualificationLevel || '';
    const indexA = qualificationOrder.indexOf(qualA);
    const indexB = qualificationOrder.indexOf(qualB);
    return indexA - indexB;
  });
  return sorted;
};

const SubjectsTable = ({ subjectList }) => {
  const { classes } = useStyles();
  const sortedList = sortSubjectList(subjectList);

  const groupedSubjects = sortedList.reduce((acc, item) => {
    const subjectName = item.subject?.subjectName || '';
    const qualification = item.qualificationLevel?.qualificationLevel || '';
    const key = `${subjectName}---${qualification}`;
    if (!acc[key]) {
      acc[key] = {
        subjectName,
        qualification,
        examBoards: [],
      };
    }
    if (item.examBoard?.examBoardName) {
      acc[key].examBoards.push(item.examBoard.examBoardName);
    }
    return acc;
  }, {});

  const finalList = Object.values(groupedSubjects);

  return (
    <Table size="medium">
      <TableHead>
        <TableRow>
          <TableCell className={classes.itemTitle} style={{ border: 'none' }} sx={{ width: '30%' }}>
            Subject
          </TableCell>
          <TableCell className={classes.itemTitle} style={{ border: 'none' }} sx={{ width: '35%' }}>
            Qualification
          </TableCell>
          <TableCell className={classes.itemTitle} style={{ border: 'none' }} sx={{ width: '35%' }}>
            Exam board(s)
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {finalList.map((group, index) => (
          <TableRow key={index}>
            <TableCell style={{ border: 'none' }} className={classes.itemTitle2}>
              {group.subjectName}
            </TableCell>
            <TableCell style={{ border: 'none' }}>
              {group.qualification}
            </TableCell>
            <TableCell style={{ border: 'none' }}>
              {group.examBoards.join(', ')}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const defaultNewLearnerDetails = (parent) => ({
  academicYear: {
    value: '',
    id: undefined,
  },
  noDetails: false,
  contact: {
    email: true,
    text: true,
  },
  aboutYou: undefined,
  currentSchool: undefined,
  emailAddress: undefined,
  firstName: undefined,
  lastName: undefined,
  ownAccount: true,
  phoneNumberPrefix: {
    value: '',
    id: undefined,
  },
  phoneNumber: undefined,
  previousSchools: [],
  subjectList: [
    {
      subject: {
        subjectName: '',
        id: undefined,
      },
      qualificationLevel: {
        qualificationLevel: '',
        id: undefined,
      },
      examBoard: {
        examBoardName: '',
        id: undefined,
      },
    },
  ],
  parent: {
    id: parent.id,
    firstName: parent.firstName,
    lastName: parent.lastName,
    emailAddress: parent.emailAddress,
  },
  userType: 'learner',
});

const Profile = () => {
  const { classes } = useStyles();
  const { UserType } = config;
  const [editForm, setEditForm] = useState(null);
  const [showAgreement, setShowAgreement] = useState(null);
  const [loadingAgreement, setLoadingAgreement] = useState(false);

  const handleOnClose = () => setEditForm(null);
  const {
    onSubmit,
    onSubmitCredentials,
    onSubmitAutomaticPayment,
    error,
    success,
    user,
  } = useEditUserForm({ onClose: handleOnClose });

  const {
    fullName,
    emailAddress,
    phoneNumber,
    phoneNumberPrefix,
    addressLine1,
    addressLine2,
    countryName,
    city,
    postCode,
    dateOfBirth,
    school,
    subjectList,
    learners,
    firstName,
    extraDegrees,
    extraSchools,
    lastName,
    aboutYou,
    currentSchool,
    previousSchools,
    academicYear,
    academicType,
    academicValue,
    userType,
    degree,
    createdAt,
    agreement,
    automaticPayment,
  } = user;

  const [automaticPaymentEnabled, setAutomaticPaymentEnabled] = useState({
    automaticPayment: automaticPayment ?? false,
  });

  const extraSchoolsList = extraSchools?.map(({ name }) => `${name}`) ?? [];

  const learnersList = learners
    ?.map((learner) => ({
      id: learner?.id,
      title: 'Student Name',
      value: `${learner?.firstName} ${learner?.lastName}`,
    }))
    ?.sort((a, b) => a.value.localeCompare(b.value, 'en-GB', { sensitivity: 'base' }));

  const personalDetails = {
    tutor: {
      values: [
        { title: 'Name', value: fullName },
        { title: 'Phone number', value: `${phoneNumberPrefix?.value} ${phoneNumber}` },
        {
          title: 'Address',
          value: [
            addressLine1,
            addressLine2,
            city,
            countryName?.value,
            postCode,
          ],
        },
        { title: 'Date Of Birth', value: dayjs(dateOfBirth).format('D MMMM YYYY') },
      ],
      formData: {
        firstName,
        lastName,
        phoneNumber,
        phoneNumberPrefix,
        addressLine1,
        addressLine2,
        city,
        countryName,
        postCode,
        dateOfBirth,
      },
      title: 'Update your personal details',
      type: 'personalDetails',
      validationSchema: validationSchemas.updatePersonalDetailsSchema,
      onSubmit,
    },
    parent: {
      values: [
        { title: 'Name', value: fullName },
        { title: 'Phone number', value: `${phoneNumberPrefix?.value} ${phoneNumber}` },
        {
          title: 'Address',
          value: [
            addressLine1,
            addressLine2,
            city,
            countryName?.value,
            postCode,
          ],
        },
      ],
      formData: {
        firstName,
        lastName,
        phoneNumber,
        phoneNumberPrefix,
        addressLine1,
        addressLine2,
        city,
        countryName,
        postCode,
        dateOfBirth,
      },
      title: 'Update your personal details',
      type: 'personalDetails',
      validationSchema: validationSchemas.updatePersonalDetailsSchema,
      onSubmit,
    },
    learner: {
      values: [
        { title: 'Name', value: fullName },
        { title: 'Phone number', value: `${phoneNumberPrefix?.value ?? ''} ${phoneNumber ?? ''}` },
      ],
      formData: {
        firstName,
        lastName,
        phoneNumber,
        phoneNumberPrefix,
      },
      title: 'Update your personal details',
      type: 'personalDetailsLearner',
      validationSchema: validationSchemas.learnerAccountSchema,
      onSubmit,
    },
    admin: {
      values: [{ title: 'Name', value: fullName }],
      formData: {
        firstName,
        lastName,
      },
      title: 'Update your personal details',
      type: 'personalDetailsBasic',
      validationSchema: validationSchemas.adminAccountSchema,
      onSubmit,
    },
  };

  const degreesArray = [
    {
      level: degree?.level || '',
      subject: degree?.subject || '',
      university: degree?.university || '',
    },
    ...(extraDegrees?.length
      ? extraDegrees.map((ed) => ({
        level: ed.level || '',
        subject: ed.subject || '',
        university: ed.university || '',
      }))
      : []),
  ];

  degreesArray.sort((a, b) => {
    const aIndex = degreeLevelOptions.indexOf(a.level);
    const bIndex = degreeLevelOptions.indexOf(b.level);
    return aIndex - bIndex;
  });

  const degrees = { values: degreesArray };

  const aboutYouDetails = {
    values: [
      {
        title: 'Schools',
        value: [
          `${school?.name} - ${
            school?.current ? 'Currently working here' : 'Not currently working here'
          }`,
          ...extraSchoolsList,
        ],
        marginItemTop: true,
      },
      {
        title: '',
        value: <SubjectsTable subjectList={subjectList} />,
        isWide: true,
      },
      { title: 'About You', value: aboutYou },
    ],
    formData: {
      school,
      subjectList,
      aboutYou,
      degree,
      extraDegrees,
      extraSchools,
    },
    title: 'Update your details',
    type: 'aboutYou',
    validationSchema: validationSchemas.tutorInfoPanelSchema,
    onSubmit,
  };

  const schoolInfoDetails = {
    values: [
      { title: 'Academic Year', value: `${academicType?.value || ''} ${academicValue?.value || ''}` },
      { title: 'School', value: currentSchool },
      { title: 'Subjects', value: <SubjectsTable subjectList={subjectList} /> },
      { title: 'Additional Information', value: aboutYou },
    ],
    formData: {
      currentSchool,
      subjectList,
      academicYear,
      academicType,
      academicValue,
      previousSchools,
      aboutYou,
    },
    title: 'Update your details',
    type: 'schoolInfo',
    validationSchema: validationSchemas.learnerInfoSchema,
    onSubmit,
  };

  const viewAgreement = async () => {
    setLoadingAgreement(true);
    try {
      const [res] = await getAgreement();
      window.open(res?.output?.agreementUrl, '_blank').focus();
    } catch (err) {
    } finally {
      setLoadingAgreement(false);
    }
  };

  const handleShowAgreement = () => {
    if (agreement === 'signed') {
      viewAgreement();
    } else {
      setShowAgreement(true);
    }
  };

  const handleAutoPaymentChange = async () => {
    setAutomaticPaymentEnabled({
      automaticPayment: !automaticPaymentEnabled.automaticPayment,
    });
    await onSubmitAutomaticPayment(automaticPayment);
  };

  const accountDetails = {
    values: [
      { title: 'Email', value: emailAddress },
      { title: 'Password', value: '*********' },
      ...(userType === UserType.LEARNER
        ? []
        : [
          {
            title: 'Agreement',
            value: (
              <Button
                variant="outlined"
                size="small"
                onClick={handleShowAgreement}
                disabled={loadingAgreement}
              >
                View
              </Button>
            ),
          },
        ]),
      ...(userType === UserType.PARENT
        ? [
          {
            title: 'Automatic Payment',
            value: (
              <Switch
                checked={automaticPaymentEnabled.automaticPayment}
                onChange={handleAutoPaymentChange}
              />
            ),
          },
        ]
        : []),
    ],
    formData: {
      emailAddress,
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    title: 'Update account details',
    type: 'account',
    validationSchema: validationSchemas.changePasswordSchema,
    onSubmit: onSubmitCredentials,
  };

  const handleOnEditItem = (id) => {
    const learnerData = id
      ? learners.find((l) => l?.id === id) ?? []
      : defaultNewLearnerDetails(user);
    setEditForm({
      formData: learnerData,
      title: 'Learners details',
      type: id ? 'parentLearnerEdit' : 'parentLearnerCreate',
      validationSchema: id
        ? validationSchemas.parentLearnerAccountSchemaEdit
        : validationSchemas.parentLearnerAccountSchemaNew,
      onSubmit,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <PageTitle title="Profile" />
      </div>
      <Grid container className={classes.grid}>
        <Grid item xs={12}>
          <ProfileUser userType={userType} fullName={fullName} size="xl" />
        </Grid>
        {error && <Alert severity="error" className={classes.alert}>{error}</Alert>}
        {success && <Alert severity="success" className={classes.alert}>{success}</Alert>}
        <Grid item xs={12} className={classes.infoContainer}>
          <div className={classes.titleContainer}>
            <Typography variant="body1" className={classes.title}>
              Account details
            </Typography>
            <IconButton
              className={classes.editIcon}
              onClick={() => setEditForm(accountDetails)}
              data-testid="edit-account-details"
            >
              <EditIcon />
            </IconButton>
          </div>
          <div className={classes.itemList}>
            {accountDetails.values.map((item) => (
              <ProfileItem title={item?.title} value={item?.value} key={item?.title} />
            ))}
          </div>
        </Grid>
        <Grid item xs={12} className={classes.infoContainer}>
          <div className={classes.titleContainer}>
            <Typography variant="body1" className={classes.title}>
              Personal details
            </Typography>
            <IconButton
              className={classes.editIcon}
              onClick={() => setEditForm(personalDetails[userType])}
            >
              <EditIcon />
            </IconButton>
          </div>
          <div className={classes.itemList}>
            {userType
              && personalDetails[userType].values.map((item) => (
                <ProfileItem title={item?.title} value={item?.value} key={item?.title} />
              ))}
          </div>
        </Grid>

        {userType === UserType.TUTOR && (
          <Grid item xs={12} className={classes.infoContainer}>
            <div className={classes.titleContainer}>
              <Typography variant="body1" className={classes.title}>
                About you
              </Typography>
              <IconButton
                className={classes.editIcon}
                onClick={() => setEditForm(aboutYouDetails)}
              >
                <EditIcon />
              </IconButton>
            </div>
            <div className={classes.itemList}>
              {degrees.values.map((degItem, index) => (
                <ProfileItem
                  key={index}
                  title={degItem.level}
                  value={`${degItem.subject}, ${degItem.university}`}
                />
              ))}
              {aboutYouDetails.values.map((item) => (
                <ProfileItem
                  title={item?.title}
                  value={item?.value}
                  key={item?.title}
                  isWide={item?.isWide === true}
                  marginItemTop={item?.marginItemTop === true}
                />
              ))}
            </div>
          </Grid>
        )}

        {userType === UserType.LEARNER && (
          <Grid item xs={12} className={classes.infoContainer}>
            <div className={classes.titleContainer}>
              <Typography variant="body1" className={classes.title}>
                Current School Info
              </Typography>
              <IconButton
                className={classes.editIcon}
                onClick={() => setEditForm(schoolInfoDetails)}
              >
                <EditIcon />
              </IconButton>
            </div>
            <div className={classes.itemList}>
              {schoolInfoDetails.values.map((item) => (
                <ProfileItem title={item?.title} value={item?.value} key={item?.title} />
              ))}
            </div>
          </Grid>
        )}

        {userType === UserType.PARENT && (
          <Grid item xs={12} className={classes.infoContainer}>
            <div className={classes.titleContainer}>
              <Typography variant="body1" className={classes.title}>
                Learners
              </Typography>
            </div>
            <div className={classes.itemList}>
              {learnersList.map((item) => (
                <ProfileItem
                  title={item?.title}
                  value={item?.value}
                  key={item?.id}
                  onEditItem={() => handleOnEditItem(item?.id)}
                />
              ))}
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  paddingTop: '8px',
                }}
              >
                <Button onClick={() => handleOnEditItem()}>Add learner</Button>
              </div>
            </div>
          </Grid>
        )}
      </Grid>

      {Boolean(editForm) && (
        <Modal open={Boolean(editForm)} onClose={handleOnClose} className={classes.modal}>
          <div className={classes.modalContainer}>
            <EditForm
              formData={editForm.formData}
              title={editForm.title}
              type={editForm.type}
              validationSchema={editForm.validationSchema}
              onSubmit={editForm.onSubmit}
              handleOnClose={handleOnClose}
            />
          </div>
        </Modal>
      )}
      {showAgreement && (
        <Dialog open={showAgreement} onClose={() => setShowAgreement(false)}>
          <DialogContent>
            {userType === UserType.TUTOR ? (
              <TutorAgreement data={{ firstName, lastName, createdAt }} />
            ) : (
              <ParentAgreement data={{ firstName, lastName, createdAt }} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowAgreement(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Profile;
