// file: apps/dashboard/src/pages/profile/hooks/useEditUserForm.js
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import useAuth from '~common/utils/hooks/useAuth';
import useUserStore, { userSelector } from '../../../store/userStore';

const useEditUserForm = ({ onClose }) => {
  const {
    user, getUser, updateUser, createLearner,
  } = useUserStore(userSelector);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { handleChangePassword } = useAuth({ Hub, Auth, bypassCache: false });

  const handleGetUser = useCallback(async () => getUser(), [getUser]);

  useEffect(() => {
    handleGetUser();
  }, [handleGetUser]);

  const onSubmit = async ({ data, type }) => {
    const formData = type === 'parentLearnerEdit' ? { learners: [data] } : data;

    console.log(formData);

    if (data?.dbsUploadFileName) {
      formData.dbsUploadFileName = data?.dbsUploadFileName;
    }
    if (data?.cvUploadFileName) {
      formData.cvUploadFileName = data?.cvUploadFileName;
    }
    if (formData?.dateOfBirth) {
      formData.dateOfBirth = dayjs(formData?.dateOfBirth).format('YYYY-MM-DD');
    }

    setSuccess(null);
    setError(null);

    if (type === 'parentLearnerCreate') {
      const result = await createLearner(formData);
      if (result.isOk) {
        setSuccess(`Successfully added ${formData.firstName} ${formData.lastName}`);
      } else {
        setError(
          `There has been an error adding ${formData.firstName} ${formData.lastName}, please try again later or contact an administrator`,
        );
      }
    } else {
      const result = await updateUser({ formData });
      if (result.isOk) {
        setSuccess('Successfully updated.');
      } else {
        setError('There has been an error submitting the form please try again or contact an administrator.');
      }
    }
    onClose();
  };

  const onSubmitCredentials = async ({ data, dirtyFields }) => {
    const { currentPassword, password, confirmPassword } = dirtyFields;

    setSuccess(null);
    setError(null);

    if (currentPassword && password && confirmPassword) {
      const res = await handleChangePassword({
        currentPassword: data.currentPassword,
        newPassword: data.password,
      });

      if (res === 'ERROR') {
        setError('Unable to change your password at this time, please try again later.');
      } else if (res === 'LIMIT') {
        setError('Password change limit reached. Please try again later.');
      } else if (res === 'NOAUTH') {
        setError('Unable to change your password. Incorrect current password');
      } else if (res === 'SUCCESS') {
        setSuccess('Succefully changed your credentials.');
      }
    }
    onClose();
  };

  const onSubmitAutomaticPayment = async () => {
    const formData = {
      automaticPayment: !user.automaticPayment,
    };

    const result = await updateUser({ formData });
    if (result.isOk) {
      setSuccess('Successfully updated.');
    } else {
      setError('There has been an error submitting the form please try again or contact an administrator.');
    }
  };

  return {
    onSubmit,
    onSubmitCredentials,
    onSubmitAutomaticPayment,
    error,
    success,
    user,
  };
};

export default useEditUserForm;
